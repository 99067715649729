import { useEffect, useState } from "react";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { OverallViewTable } from "../../../revamped-overall-view/components/shared/OverallViewTable";
import { EmptyTableView } from "../../reusable/EmptyTableView";
import { primaryColors } from "../../../../helpers/customColors";
import { TableContainer } from "../../../revamped-overall-view/components/shared/TableContainer";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";
import { defaultBranchesListParams } from "../../api/branches-divisions/getAllBranchesPaginated";
import { CreateTemplateDialog } from "./CreateTemplateDialog";
import { useGetAllTemplates } from "../../api/templates/getAllTemplates";
import moment from "moment";
import { GenericDialog } from "../../../../reusable/components/GenericDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetTemplateQuestions } from "../../api/templates/getTemplateQuestions";
import { useGetAllBranchesWithoutPagination } from "../../api/branches-divisions";
import {
  assignTemplateToEntities,
  useAssignTemplateToEntities,
} from "../../api/templates/assignTemplateToEntities";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { SuggestNewQuestionDialog } from "./SuggestNewQuestionDialog";

export const TemplatesTable = () => {
  const [rowPanelDetailsType, setRowPanelDetailsType] = useState(
    rowPanelDetailsTypes.default
  );

  const { apiListArgsState, updatePageHandler } = useListAPIsStateManagement({
    initialState: {
      ...defaultBranchesListParams,
    },
  });

  const {
    data: templatesServerResponse,
    isLoading: isLoadingTemplatesServerResponse,
    isFetching: isFetchingTemplatesServerResponse,
    isError: errorInTemplatesServerResponse,
  } = useGetAllTemplates({ params: { ...apiListArgsState } });

  const templatesTableColumns = [
    {
      header: "ID",
      accessorKey: "id",
      size: 50,
    },
    {
      header: "Name",
      accessorKey: "name",
      size: 50,
    },
    {
      header: "Created At",
      accessorFn: function renderTemplateCreationDateRowCell(templateData) {
        return moment(templateData.created_at).format("LLL");
      },
      size: 50,
    },
    {
      header: "Assigned Branches",
      accessorFn: function renderTemplateAssignedBranchesRowCell(templateData) {
        const templateAssignedBranches = [];
        templateData.branches.forEach(function appendAssignedBranch(
          assignedBranch
        ) {
          templateAssignedBranches.push(assignedBranch?.name);
        });

        return (
          <BadgesDialog
            badgesTitles={templateAssignedBranches}
            bgColor={primaryColors.gray[300]}
            textColor={primaryColors.base.black}
            header={`Assigned Branches for Template ID #${templateData.id}`}
            triggerButtonTitle={`Show all ${templateAssignedBranches.length} Branches`}
          />
        );
      },
      size: 50,
    },
    {
      header: "Assigned Divisions",
      accessorFn: function renderTemplateAssignedDivisionsRowCell(
        templateData
      ) {
        const templateAssignedDivisions = [];
        templateData.divisions.forEach(function renderAssignedDivision(
          assignedDivision
        ) {
          templateAssignedDivisions.push(assignedDivision?.name);
        });
        
        return (
          <BadgesDialog
            badgesTitles={templateAssignedDivisions}
            bgColor={primaryColors.gray[300]}
            textColor={primaryColors.base.black}
            header={`Assigned Divisions for Template ID #${templateData.id}`}
            triggerButtonTitle={`Show all ${templateAssignedDivisions.length} Divisions`}
          />
        );
      },
      size: 50,
    },
    {
      header: "Assigned Channels",
      accessorFn: function renderTemplateAssignedChannelRowCell(templateData) {
        const templateAssignedChannels =
          extractTemplateSelectedChannelsFromBranchesAndDivisions(templateData);

        return (
          <BadgesDialog
            // Use a set to removce duplicate Channels
            badgesTitles={[...new Set(templateAssignedChannels)]}
            bgColor={primaryColors.gray[300]}
            textColor={primaryColors.base.black}
            header={`Assigned Channels for Template ID #${templateData.id}`}
            triggerButtonTitle={`Show all ${templateAssignedChannels.length} Channels`}
          />
        );
      },
      size: 50,
    },
  ];

  const noTemplatesView = (
    <EmptyTableView
      title="Start by adding your Templates"
      description="Set up your Tempaltes here"
      actionButton={
        <CreateTemplateDialog
          // reachedMaxTemplatesLimit={reachedTemplatesMaxLimit}
          reachedMaxTemplatesLimit={false}
        />
      }
    />
  );

  const branhesAndDivisionsTableView = (
    <OverallViewTable
      columns={templatesTableColumns}
      data={templatesServerResponse?.data || []}
      totalRecords={templatesServerResponse?.data?.length}
      isLoading={isLoadingTemplatesServerResponse}
      isError={errorInTemplatesServerResponse}
      externalPaginationProps={{
        externalCurrentPage: 1,
        externalPageSize: 300,
        isFetching: isFetchingTemplatesServerResponse,
      }}
      resetRowPanelDetailsTypeCallback={function setRowPanelDetailsTypeToDefaultOnClose() {
        setRowPanelDetailsType(rowPanelDetailsTypes.default);
      }}
      renderRowDetails={({ row }) => {
        return renderDynamicRowPanelDetails({
          rowPanelDetailsTypeArg: rowPanelDetailsType,
          row,
        });
      }}

    />
  );

  function renderDynamicRowPanelDetails({ rowPanelDetailsTypeArg, row }) {
    switch (rowPanelDetailsTypeArg) {
      case rowPanelDetailsTypes.default:
        return (
          <TemplateDetailsAndAssignationTableRowPanel
            templateDetails={row.original}
          />
        );
      //   case rowPanelDetailsTypes.templateAssignation:
      //     return (
      //       <TemplateAssignationTableRowPanel templateID={row.original.id} />
      //     );
      default: {
        return null;
      }
    }
  }

  let branchesAndDivisionsSectionView = null;

  if (
    isLoadingTemplatesServerResponse ||
    templatesServerResponse?.data?.length > 0
  ) {
    branchesAndDivisionsSectionView = branhesAndDivisionsTableView;
  }

  if (
    !isLoadingTemplatesServerResponse &&
    templatesServerResponse?.data?.length === 0
  ) {
    branchesAndDivisionsSectionView = noTemplatesView;
  }

  return (
    <TableContainer
      label={"Templates"}
      tableActionsNextToName={<Box display="flex" alignItems="center" gap={2}>
        <SuggestNewQuestionDialog />
        <CreateTemplateDialog />
      </Box>}
    >
      {branchesAndDivisionsSectionView}
    </TableContainer>
  );
};

const rowPanelDetailsTypes = {
  default: "default",
  templateAssignation: "template-assignation",
};

const TemplateDetailsAndAssignationTableRowPanel = ({
  templateDetails,
  maxDivisionsLimit,
}) => {
  const {
    data: templateQuestionsServerResponse,
    isLoading: isLoadingTemplateQuestionsServerResponse,
  } = useGetTemplateQuestions({
    params: { templateID: templateDetails.id },
  });

  const englishTemplateQuestions = templateQuestionsServerResponse?.data.find(
    function getEnglishTemplateQuestions(templateQuestionsLocalized) {
      return templateQuestionsLocalized.lang === "en";
    }
  )?.questions;

  return (
    <Grid container bgcolor={primaryColors.gray[50]}>
      <Grid xs={4} borderRight={`2px ${primaryColors.gray[200]} solid`} p={2}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontWeight="bold">Template Questions</Typography>
          <Box
            maxHeight="700px"
            sx={{
              overflowY:
                englishTemplateQuestions?.length > 4 ? "scroll" : "undefined",
            }}
          >
            <IsLoadingContainer
              isLoading={isLoadingTemplateQuestionsServerResponse}
            >
              {templateQuestionsServerResponse &&
                englishTemplateQuestions.map(function renderQuestionAccordion(
                  questionData
                ) {
                  return (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography component="span">
                          {questionData.text}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          display="flex"
                          gap={2}
                          width="100%"
                          flexWrap="wrap"
                        >
                          {questionData.choices.map(
                            function renderQuestionChoice(
                              questionChoiceData,
                              index
                            ) {
                              return (
                                <Box
                                  width="fit-content"
                                  bgcolor={primaryColors.gray[300]}
                                  borderRadius={3}
                                  whiteSpace="nowrap"
                                  px={1}
                                >
                                  {questionChoiceData.text}
                                </Box>
                              );
                            }
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </IsLoadingContainer>
          </Box>
        </Box>
      </Grid>
      <Grid xs={8} p={2}>
        <Box display="flex" flexDirection="column" gap={2} height="100%">
          <Typography fontWeight="bold">Template Assignation</Typography>
          <Grid
            xs={12}
            container
            p={1}
            borderTop={`2px ${primaryColors.gray[200]} solid`}
          >
            <Grid
              xs={4}
              borderRight={`2px ${primaryColors.gray[200]} solid`}
              pr={1}
            >
              <Box display="flex" flexDirection="column" gap={1} height="100%">
                <Typography>Assign to Branch(es)</Typography>
                <Box
                  borderTop={`2px ${primaryColors.gray[200]} solid`}
                  pt={3}
                >
                  <AssignTemplateToOneOrMultipleBranches
                    templateDetails={templateDetails}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              xs={4}
              borderRight={`2px ${primaryColors.gray[200]} solid`}
              pr={1}
              pl={1}
            >
              <Box display="flex" flexDirection="column" gap={1} height="100%">
                <Typography>Assign to Division(s)</Typography>
                <Box
                  height="100%"
                  borderTop={`2px ${primaryColors.gray[200]} solid`}
                  pt={3}
                >
                  <AssignTemplateToOneOrMultipleDivisions
                    templateDetails={templateDetails}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={4} height="300px" pl={1}>
              <Box display="flex" flexDirection="column" gap={1} height="100%">
                <Typography>Assign to Channel(s)</Typography>
                <Box
                  height="100%"
                  borderTop={`2px ${primaryColors.gray[200]} solid`}
                  pt={3}
                >
                  <AssignTemplateToOneOrMultipleChannels
                    templateDetails={templateDetails}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

function AssignTemplateToOneOrMultipleBranches({ templateDetails }) {
  const [selectedBranches, setSelectedBranches] = useState([]);

  const assignTemplateToBranchesMutation = useAssignTemplateToEntities();

  const updateSelectedBranchesHandler = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedBranches(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const { data: allBranchesServerResponse } =
    useGetAllBranchesWithoutPagination();

  useEffect(
    function setDetaultSelectedBranchesIfAny() {
        const defaultSelectedBranchesIDs = templateDetails.branches.map(
          function mapBranchToID(branchDetails) {
            return branchDetails.id;
          }
        );
        setSelectedBranches(defaultSelectedBranchesIDs);
    },
    [templateDetails]
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={2}
      alignItems="center"
    >
      <FormControl sx={{ width: "270px" }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Select Branch(es)
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedBranches}
          onChange={updateSelectedBranchesHandler}
          input={<OutlinedInput label="Select Branch(es)" />}
          renderValue={(selected) => {
            const selectedBranchesNames = selected.map(
              function mapBranchIDToName(selectedBranchID) {
                return allBranchesServerResponse?.data.find(
                  function matchByBranchID(branchDetails) {
                    return branchDetails.id === selectedBranchID;
                  }
                )?.name;
              }
            );
            return (
              <Box whiteSpace="normal" wordBreak="break-word">
                {selectedBranchesNames.join(", ")}
              </Box>
            );
          }}
          sx={{
            width: "270px",
          }}
        >
          {allBranchesServerResponse?.data.map((branchDetails) => (
            <MenuItem key={branchDetails.id} value={branchDetails.id}>
              <Checkbox checked={selectedBranches.includes(branchDetails.id)} />
              <ListItemText primary={branchDetails?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <LoadingButton
        onClick={function saveChangesInAssignedBranches() {
          assignTemplateToBranchesMutation.mutateAsync({
            templateID: templateDetails.id,
            branches: selectedBranches,
          });
        }}
        loading={assignTemplateToBranchesMutation.isLoading}
        sx={{
          textTransform: "capitalize",
          width: "fit-content",
          alignSelf: "flex-end",
          color: primaryColors.gray[500],
          borderColor: primaryColors.gray[500],
          "&:hover": {
            borderColor: primaryColors.gray[500]
          }
        }}
        size="small"
        variant="outlined"
        startIcon={<SaveIcon />}
      >
        Save
      </LoadingButton>
    </Box>
  );
}

function AssignTemplateToOneOrMultipleDivisions({ templateDetails }) {
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const assignTemplateToBranchesMutation = useAssignTemplateToEntities();

  const updateSelectedDivisionsHandler = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDivisions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const { data: allBranchesServerResponse } =
    useGetAllBranchesWithoutPagination();

  useEffect(
    function prepareAndSetDivisionsValues() {
      const tempDivisions = [];
      allBranchesServerResponse?.data.forEach(
        function extractDivisionsFromBranch(branchDetails) {
          branchDetails.divisions.forEach(
            function appendDivisionToDivisionsArray(divisionDetails) {
              tempDivisions.push(divisionDetails);
            }
          );
        }
      );

      setDivisions(tempDivisions);
    },
    [allBranchesServerResponse?.data]
  );

  useEffect(
    function setDetaultSelectedDivisionsIfAny() {
        const defaultSelectedDivisionsIDs = templateDetails.divisions.map(
          function mapDivisionToID(divisionDetails) {
            return divisionDetails.id;
          }
        );
        setSelectedDivisions(defaultSelectedDivisionsIDs);
    },
    [templateDetails]
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <FormControl sx={{ width: "270px" }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Select Division(s)
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedDivisions}
          onChange={updateSelectedDivisionsHandler}
          input={<OutlinedInput label="Select Division(s)" />}
          renderValue={(selected) => {
            const selectedDivisionsNames = selected.map(
              function mapDivisonIDToName(selectedDivisonID) {
                return divisions.find(function matchByDivisonID(
                  divisionDetails
                ) {
                  return divisionDetails.id === selectedDivisonID;
                })?.name;
              }
            );
            return (
              <Box whiteSpace="normal" wordBreak="break-word">
                {selectedDivisionsNames.join(", ")}
              </Box>
            );
          }}
          sx={{
            width: "270px",
          }}
        >
          {divisions.map((divisionDetails) => {
            return (
              <MenuItem key={divisionDetails.id} value={divisionDetails.id}>
                <Checkbox
                  checked={selectedDivisions.includes(divisionDetails.id)}
                />
                <ListItemText primary={divisionDetails?.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <LoadingButton
        onClick={function saveChangesInAssignedBranches() {
          assignTemplateToBranchesMutation.mutateAsync({
            templateID: templateDetails.id,
            divisions: selectedDivisions,
          });
        }}
        loading={assignTemplateToBranchesMutation.isLoading}
        sx={{
          textTransform: "capitalize",
          width: "fit-content",
          alignSelf: "flex-end",
          color: primaryColors.gray[500],
          borderColor: primaryColors.gray[500],
          "&:hover": {
            borderColor: primaryColors.gray[500]
          }
        }}
        size="small"
        variant="outlined"
        startIcon={<SaveIcon />}
      >
        Save
      </LoadingButton>
    </Box>
  );
}

function AssignTemplateToOneOrMultipleChannels({ templateDetails }) {
  const [selectedChannels, setSelectedChannels] = useState([]);

  const assignTemplateToBranchesMutation = useAssignTemplateToEntities();

  const updateSelectedChannelsHandler = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedChannels(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ChannelsDescriptionObj = {
    ALES: "All Channels Except Specified",
    APP: "On Site",
    SL: "General Link",
    QR: "QR Code",
    OTS: "Personalized Link",
    OTB: "Outbound",
  };

  const ChannelsArray = ["ALES", "APP", "SL", "QR", "OTS", "OTB"];

  useEffect(
    function setDetaultSelectedChannelsIfAny() {
      const templateAssignedChannels =
        extractTemplateSelectedChannelsFromBranchesAndDivisions(
          templateDetails
        );

        const defaultSelectedChannelsIDs = templateAssignedChannels.map(
          function mapBranchToID(channelName) {
            return channelName;
          }
        );
        setSelectedChannels(defaultSelectedChannelsIDs);
    },
    [templateDetails]
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <FormControl sx={{ width: "270px" }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Select Channel(s)
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedChannels}
          onChange={updateSelectedChannelsHandler}
          input={<OutlinedInput label="Select Channel(s)" />}
          renderValue={(selected) => (
            <Box whiteSpace="notmal" wordBreak="break-word">
              {selected.join(", ")}
            </Box>
          )}
          sx={{
            width: "270px",
          }}
        >
          {ChannelsArray.map((channelName) => (
            <MenuItem key={channelName} value={channelName}>
              <Checkbox checked={selectedChannels.includes(channelName)} />
              <Box display="flex" flexDirection="column">
                <ListItemText primary={channelName} />
                <FormHelperText>
                  {ChannelsDescriptionObj[channelName]}
                </FormHelperText>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <LoadingButton
        onClick={function saveChangesInAssignedBranches() {
          assignTemplateToBranchesMutation.mutateAsync({
            templateID: templateDetails.id,
            channels: selectedChannels,
          });
        }}
        loading={assignTemplateToBranchesMutation.isLoading}
        sx={{
          textTransform: "capitalize",
          width: "fit-content",
          alignSelf: "flex-end",
          color: primaryColors.gray[500],
          borderColor: primaryColors.gray[500],
          "&:hover": {
            borderColor: primaryColors.gray[500]
          }
        }}
        size="small"
        variant="outlined"
        startIcon={<SaveIcon />}
      >
        Save
      </LoadingButton>
    </Box>
  );
}

function BadgesDialog({
  header,
  triggerButtonTitle,
  badgesTitles,
  textColor,
  bgColor,
}) {
  const renderHorizontallyInModal = badgesTitles.length > 5;

  const flexedBadges = (
    <Box
      display="flex"
      flexDirection={renderHorizontallyInModal ? "row" : "column"}
      gap={1}
      flexWrap="wrap"
      justifyContent="center"
      width="inherit"
    >
      {badgesTitles.map(function renderBadge(badgeTitle, index) {
        return (
          <Box
            marginX={renderHorizontallyInModal ? 2 : undefined}
            padding={1}
            color={textColor}
            backgroundColor={bgColor}
            fontSize="12px"
            borderRadius={5}
            whiteSpace="nowrap"
            width="fit-content"
          >
            {badgeTitle}
          </Box>
        );
      })}
    </Box>
  );
  return !renderHorizontallyInModal ? (
    flexedBadges
  ) : (
    <GenericDialog
      dialogTitle={header}
      maxWidth="xs"
      triggerButton={
        <Button
          title={triggerButtonTitle}
          onClick={function (e) {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Typography
            variant="text-sm"
            sx={{ textTransform: "capitalize" }}
            color={primaryColors.brand[600]}
          >
            {triggerButtonTitle}
          </Typography>
        </Button>
      }
      closeButtonText={"Cancel"}
    >
      {flexedBadges}
    </GenericDialog>
  );
}

function extractTemplateSelectedChannelsFromBranchesAndDivisions(
  templateDetails
) {
  const templateAssignedChannels = [];
  templateDetails.branches.forEach(function registerAssignedChannelsInBranch(
    assignedBranch
  ) {
    assignedBranch.channels.forEach(function registerAssignedChannel(
      assignedChannel
    ) {
      templateAssignedChannels.push(assignedChannel);
    });
  });

  templateDetails.branches.forEach(function registerAssignedChannelsInDivision(
    assignedDivision
  ) {
    assignedDivision.channels.forEach(function registerAssignedChannel(
      assignedChannel
    ) {
      templateAssignedChannels.push(assignedChannel);
    });
  });

  return [...new Set(templateAssignedChannels)];
}
