import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const createNewTemplate = ({
  templateName,
  selectedQuestions,
}) => {
  let requestURL = `${API_URL}/templates`;

  return melior.post(requestURL, {
    name: templateName,
    questions_schema: selectedQuestions.map(function mapQuestionObjForTheAPI(
      questionObj
    ) {
      return { question: questionObj.id };
    }),
  });
};

export const useCreateNewTemplate = ({ config } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["templates"], { type: "active" });

      fireNotification({
        title: `New Template has been created successfully`,
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: (error, variables) => {
      fireNotification({
        title: `Template creation failed, please try again later`,
        description: error.response.data.detail,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: createNewTemplate,
  });
};
