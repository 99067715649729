import { useContext, useMemo, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import { OverallViewTable } from "../shared/OverallViewTable";
import { ComplaintTableRowDetails } from "./ComplaintTableRowDetails";
import { TableContainer } from "../shared/TableContainer";
import { DynamicSearchInput } from "../shared/DynamicSearchInput";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import {
  defaultComplaintsListParams,
  useGetComplaints,
} from "../../api/complaints/getComplaints";
import "./table-styles.css";
import { StarComplaint } from "./StarComplaint";
import SaveAs from "./SaveAs";
import moment from "moment";
import { ArchiveComplaint } from "./ArchiveComplaint";
import { Visibility } from "@mui/icons-material";
import ResolvedComplaintSVG from "../../../../assets/icons/complaints/resolved-complaint.svg";
import UnresolvedComplaintSVG from "../../../../assets/icons/complaints/unresolved-complaint.svg";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { complaintStatus } from "../shared/useOverallViewFiltersState";

export const ComplaintsTable = ({
  selectedFiltersState,

  // No longer needed as there's no archived and starred tabs any more.
  starred,
  archived,
}) => {
  const [selectedComplaintsIDs, setSelectedComplaintsIDs] = useState([]);

  const dateFrom = selectedFiltersState.date_from;
  const dateTo = selectedFiltersState.date_to;
  const selectedGovernorate = selectedFiltersState.governorates.join(",");
  const selectedFacilityTypes = selectedFiltersState.facilityTypes.join(",");
  const selectedDivisions = selectedFiltersState.divisions.join(",");
  const selectedAssignees = selectedFiltersState.complaintsAssignees.join(",");
  const selectedChannels = selectedFiltersState.channels.join(",");
  const selectedRelatedTeams =
    selectedFiltersState.selectedRelatedTeams.join(",");
  const selectedFacilityUsersString =
    selectedFiltersState.selectedFacilityUsers.join(",");

  const {
    apiListArgsState,
    updatePageHandler,
    updatePageSizeHandler,
    initiateTypedSearchHandler,
  } = useListAPIsStateManagement({
    initialState: {
      ...defaultComplaintsListParams,
    },
  });

  const complaintsQueryParams = {
    ...apiListArgsState,
    search_text: undefined,
    search_type: undefined,
    date_from: apiListArgsState.search_text ? undefined : dateFrom,
    date_to: apiListArgsState.search_text ? undefined : dateTo,
    governorates: apiListArgsState.search_text ? undefined : selectedGovernorate,
    facility_types: apiListArgsState.search_text ? undefined : selectedFacilityTypes,
    divisions: apiListArgsState.search_text ? undefined : selectedDivisions,
    channels: apiListArgsState.search_text ? undefined : selectedChannels,
    assignees: apiListArgsState.search_text ? undefined : selectedAssignees,
    related_teams: apiListArgsState.search_text
      ? undefined
      : selectedRelatedTeams,
    // comment_text: selectedFiltersState.withComment,
    archived: selectedFiltersState.archived,
    bookmarked: selectedFiltersState.bookmarked,
    status: apiListArgsState.search_text
      ? undefined
      : selectedFiltersState.complaintStatus,
    created_by: selectedFacilityUsersString,
    // bookmarked: apiListArgsState.search_text ? undefined : starred,
    // archived: apiListArgsState.search_text ? undefined : archived,
  };

  complaintsQueryParams[apiListArgsState.search_type] =
    apiListArgsState.search_text;

  const {
    data: complaintsResponse,
    isFetching: isFetchingComplaints,
    isError: complaintsFetchingError,
    isLoading: isLoadingComplaints,
  } = useGetComplaints({
    params: { ...complaintsQueryParams },
  });

  const { complaintsTableColumns } = useComplaintsTableColumns();

  return !complaintsResponse ? null : (
    <TableContainer
      label={"Complaint Responses"}
      tableActionsNextToName={
        <Box display="flex" alignItems="center" gap={2}>
          <SaveAs
            queryStringsForAPI={complaintsQueryParams}
            selectedComplaintsIDs={
              selectedComplaintsIDs === "all" ? [] : selectedComplaintsIDs
            }
            disabled={selectedComplaintsIDs?.length === 0}
          />
          <DynamicSearchInput
            initiateSearchCallback={({ type, value }) =>
              initiateTypedSearchHandler({ type, value })
            }
            resetSearchCallback={() =>
              initiateTypedSearchHandler({ type: "", value: "" })
            }
            withMRN
            withPhone
          />
        </Box>
      }
    >
      <OverallViewTable
        columns={complaintsTableColumns}
        data={complaintsResponse.data.results.map(
          function setRowBackgroundColorForArchivedComplaint(complaintData) {
            return {
              ...complaintData,
              styles: {
                backgroundColor: complaintData.archived
                  ? primaryColors.error[100]
                  : "",
              },
            };
          }
        )}
        totalRecords={complaintsResponse?.data.total_count}
        isLoading={isLoadingComplaints}
        isError={complaintsFetchingError}
        externalPaginationProps={{
          externalCurrentPage: apiListArgsState.page,
          updateExternalCurrentPage: updatePageHandler,

          externalPageSize: apiListArgsState.pageSize,
          updatePageSizeHandler,

          isFetching: isFetchingComplaints,
        }}
        uniqueColumnName="id"
        rowsSelectionHandling={{
          updaterHandler: function updateSelectedComplaintsIDs(
            latestSelectedRowsIDs
          ) {
            setSelectedComplaintsIDs(latestSelectedRowsIDs);
          },
        }}
        renderRowDetails={({ row }) => {
          return (
            <ComplaintTableRowDetails
              isFetchingComplaintsList={isFetchingComplaints}
              complaintDetails={row.original}
              isExpanded={row.getIsExpanded()}
            />
          );
        }}
        withRowSelection
      />
    </TableContainer>
  );
};

const complaintsTableSelecterFiltersInitialState = {
  // Default Date Range is the past Week
  questions: [],
};

const complaintsTableFiltersReducer = (state, action) => {
  switch (action.type) {
    case complaintTableFiltersDispatchActions["UPDATE-SELECTED-QUESTIONS"]: {
      return {
        ...state,
        questions: action.payload.questions,
      };
    }
    default: {
      return state;
    }
  }
};

export const complaintTableFiltersDispatchActions = {
  "UPDATE-SELECTED-QUESTIONS": "UPDATE-SELECTED-QUESTIONS",
};

export const useComplaintsTableColumns = () => {
  const { canArchiveComplaints } = useContext(AuthenticationContext);

  let complaintsTableColumns = useMemo(
    () => [
      {
        header: "",
        id: "star-action",
        accessorFn: function renderComplaintStarAction(complaintData) {
          return <StarComplaint complaintRecord={complaintData} />;
        },
        size: 50,
      },
    //   {
    //     header: "ID",
    //     id: "id",
    //     accessorKey: "id",
    //     size: 50,
    //   },
    //   {
    //     header: "Visit Date",
    //     id: "visit_date",
    //     accessorFn: function renderComplaintVisitDateColumnCell(complaintData) {
    //       return complaintData.visit_date
    //         ? moment(complaintData.visit_date).format("h:mm A, DD/MM/YYYY")
    //         : "-";
    //     },
    //     size: 50,
    //   },
    //   {
    //     header: "MRN / Patient ID",
    //     id: "mrn",
    //     accessorFn: (complaintItem) => complaintItem.mrn ?? "-",
    //     size: 50,
    //   },
      {
        header: "Submitted At",
        accessorKey: "submitted_at",
        accessorFn: function renderComplaintDate(complaintData) {
          return moment(complaintData.submitted_at).format(
            "h:mm A, DD/MM/YYYY"
          );
        },
        size: 50,
      },
      {
        header: "Branch",
        id: "branch",
        accessorFn: function renderBranchName(complaintData) {
          return complaintData.branch.name;
        },
        size: 50,
      },
      {
        header: "Division",
        accessorKey: "division",
        size: 50,
      },
      {
        header: "Channel",
        accessorKey: "channel",
        size: 50,
      },
      {
        header: "Customer Name",
        id: "customerName",
        accessorFn: function renderCustomerName(complaintData) {
          return complaintData.contacts?.name;
        },
        size: 50,
      },
      {
        header: "Phone Number",
        id: "customerPhone",
        accessorFn: function renderCustomerPhoneNum(complaintData) {
          return complaintData.contacts?.phone;
        },
        size: 50,
      },
      {
        header: "Issue",
        accessorKey: "issue",
        size: 50,
      },
      {
        header: "Created By",
        id: "createdBy",
        accessorFn: function renderCreatorName(reviewData) {
          return reviewData?.created_by?.username || "-";
        },
        size: 50,
      },
      {
        header: "",
        id: "resolve-status",
        accessorFn: function renderComplaintResolvedState(complaintData) {
          let complaintStatusIcon = undefined;

          switch (complaintData.status) {
            case complaintStatus.Unattended: {
              complaintStatusIcon = (
                <Tooltip title="Unattended" placement="left">
                  <img
                    src={UnresolvedComplaintSVG}
                    style={{ width: "20px" }}
                    alt="unresolved-complaint-img"
                  />
                </Tooltip>
              );
              break;
            }
            case complaintStatus.Seen: {
              complaintStatusIcon = (
                <Tooltip title="Seen and in progress" placement="left">
                  <Visibility
                    sx={{
                      color: primaryColors.brand[600],
                    }}
                  />
                </Tooltip>
              );
              break;
            }
            case complaintStatus.Resolved: {
              complaintStatusIcon = (
                <Tooltip title="Resolved" placement="left">
                  <img
                    src={ResolvedComplaintSVG}
                    style={{ width: "20px" }}
                    alt="resolved-complaint-img"
                  />
                </Tooltip>
              );
              break;
            }
            default: {
              complaintStatusIcon = null;
            }
          }

          return complaintStatusIcon;
        },
        size: 50,
      },
      {
        header: "",
        id: "archive-action",
        accessorFn: function renderComplaintArchiveAction(complaintData) {
          return canArchiveComplaints ? (
            <ArchiveComplaint complaintRecord={complaintData} />
          ) : null;
        },
        size: 50,
      },
    ],
    [canArchiveComplaints]
  );
  return { complaintsTableColumns };
};
