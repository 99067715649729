import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../reusable/components/GenericDialog";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import AddIcon from "@mui/icons-material/Add";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useEffect, useRef, useState } from "react";
import { useGetAllQuestions } from "../../api/templates/getAllQuestions";
import { useCreateNewTemplate } from "../../api/templates/createNewTemplate";
import { LoadingButton } from "@mui/lab";

export const CreateTemplateDialog = () => {
  const [templateName, setTemplateName] = useState("");
  const [questionsOnLeft, setQuestionsOnLeft] = useState([]);
  const [questionsOnRight, setQuestionsOnRight] = useState([]);
  const [showTemplateCreationErrors, setShowTemplateCreationErrors] =
    useState(false);
  const [checked, setChecked] = useState([]);
  const isFirstRender = useRef(true);

  const { data: allQuestionsServerResponse } = useGetAllQuestions();

  const createNewTemplateMutation = useCreateNewTemplate();

  const allEnglishQuestions = allQuestionsServerResponse?.data.find(
    function getEnglishQuestionsOnly(questionsSet) {
      return questionsSet.lang === "en";
    }
  )?.questions;

  const leftChecked = intersection(checked, questionsOnLeft);
  const rightChecked = intersection(checked, questionsOnRight);

  const handleToggle = (value) => () => {
    const currentIndex = checked.findIndex(
      (currChecked) => currChecked.id === value.id
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setQuestionsOnRight(questionsOnRight.concat(questionsOnLeft));
    setQuestionsOnLeft([]);
  };

  const handleCheckedRight = () => {
    setQuestionsOnRight(questionsOnRight.concat(leftChecked));
    setQuestionsOnLeft(not(questionsOnLeft, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setQuestionsOnLeft(questionsOnLeft.concat(rightChecked));
    setQuestionsOnRight(not(questionsOnRight, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setQuestionsOnLeft(questionsOnLeft.concat(questionsOnRight));
    setQuestionsOnRight([]);
  };

  const customList = (items) => (
    <Paper
      sx={{
        width: 500,
        height: 400,
        overflow: "auto",
        borderRadius: 3,
      }}
    >
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    checked.filter((currChecked) => currChecked.id === value.id)
                      .length > 0
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.text} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  const templateNameError =
    templateName === "" ? "Template name must be added" : "";
  const selectedQuestionsError =
    questionsOnRight.length < 1 ? "Select at least one Question" : "";
  let templateCreationErrors = [];

  if (templateNameError !== "") {
    templateCreationErrors.push(templateNameError);
  }
  if (selectedQuestionsError !== "") {
    templateCreationErrors.push(selectedQuestionsError);
  }

  useEffect(
    function initializeLeftQuestionsOnFirstRender() {
      if (allEnglishQuestions && questionsOnLeft.length === 0) {
        if (isFirstRender.current) {
          setQuestionsOnLeft(allEnglishQuestions);
          isFirstRender.current = false; // Mark as not first render after first execution
        }
      }
    },
    [allEnglishQuestions, questionsOnLeft.length]
  );

  return (
    <GenericDialog
      dialogTitle="Add new Template"
      dialogSubtitle={"Select and move the needed questions to the right side"}
      maxWidth="lg"
      triggerButton={
        <Button
          title={"Create a new Template"}
          sx={{
            borderRadius: "8px",
            padding: "10px 16px",
            backgroundColor: primaryColors.brand[500],
            "&:hover": {
              backgroundColor: primaryColors.brand[500],
            },
          }}
        >
          <AddIcon sx={{ color: primaryColors.base.white }} fontSize="small" />
          <Typography
            sx={{
              textTransform: "none",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations.semiBold,
              color: primaryColors.base.white,
            }}
          >
            Add new Template
          </Typography>
        </Button>
      }
      triggerButtonDisplay="inline"
      isDone={createNewTemplateMutation.isSuccess}
      onCloseCallback={function resetPrevTemplateData() {
        setQuestionsOnRight([]);
        setTemplateName("");
        createNewTemplateMutation.reset();
        setShowTemplateCreationErrors(false);
      }}
    >
      <Box display="flex" flexDirection="column" gap={2} width="100%">
        {showTemplateCreationErrors && templateCreationErrors.length > 0 && (
          <Box>
            <Typography sx={{color: primaryColors.error[800]}}>Fix the issues below</Typography>
            <ul style={{ margin: 0, color: primaryColors.error[600] }}>
              {templateCreationErrors.map(
                function renderTemplateCreationErrorListItem(
                  templateCreationError
                ) {
                  return <li>{templateCreationError}</li>;
                }
              )}
            </ul>
          </Box>
        )}
        <Box display="flex" flexDirection="column" width="100%">
          <Typography
            sx={{
              color: primaryColors.gray[700],
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations.bold,
              marginBottom: "6px",
            }}
          >
            Template
          </Typography>
          <TextField
            name="name"
            placeholder="Template Name"
            type="text"
            value={templateName}
            onChange={function updateTemplateName(e) {
              setTemplateName(e.target.value);
            }}
            sx={{
              width: "30%",
              color: primaryColors.gray[500],
              backgroundColor: primaryColors.base.white,
              border: `1px solid ${primaryColors.gray[300]}`,
              borderRadius: "8px",
            }}
          />
        </Box>

        {/* Start: Questions Transfer Logic */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          bgcolor={primaryColors.gray[100]}
          gap={2}
          py={3}
          borderRadius={3}
        >
          <Grid item>{customList(questionsOnLeft)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" gap={2}>
              <Button
                variant="outlined"
                size="small"
                onClick={handleAllRight}
                disabled={questionsOnLeft.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleAllLeft}
                disabled={questionsOnRight.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(questionsOnRight)}</Grid>
        </Grid>
        {/* End: Questions Transfer Logic */}

        <LoadingButton
          variant="contained"
          onClick={function submitNewlyCreatedTemplate() {
            if (templateCreationErrors.length === 0) {
              setShowTemplateCreationErrors(false);

              createNewTemplateMutation.mutateAsync({
                templateName: templateName,
                selectedQuestions: questionsOnRight,
              });
            } else {
              setShowTemplateCreationErrors(true);
            }
          }}
          loading={createNewTemplateMutation.isLoading}
          sx={{
            textTransform: "capitalize",
            width: "fit-content",
            alignSelf: "flex-end",
          }}
        >
          Submit
        </LoadingButton>
      </Box>
    </GenericDialog>
  );
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
