import * as yup from "yup";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import { useSuggestNewQuestion } from "../../api/templates/suggestNewQuestion";
import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../reusable/components/GenericDialog";
import { Box, Button, Typography } from "@mui/material";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { FormProvider } from "react-hook-form";
import { primaryColors } from "../../../../helpers/customColors";
import {
  FormTextInput,
  FormDropdown,
  FormTextarea,
} from "../../../../reusable/components/form-inputs/controlled";
import { useContext } from "react";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";

export const SuggestNewQuestionDialog = () => {
  const { user } = useContext(AuthenticationContext);

  const validationSchema = yup.object({
    questionText: yup.string().required("Required"),
    questionType: yup.string().required("Required"),
    possibleTextChoices: yup.string().when("questionType", {
      is: questionTypesKeys["Text choices"],
      then: function makePossibleTextChoicesRequired(schema) {
        return schema.required("Required");
      },
      otherwise: function makePossibleTextChoicesOptional(schema) {
        return schema.notRequired();
      },
    }),
    additionalNotes: yup.string().optional(),
  });

  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    useFormProps: { mode: "onChange" },
  });
  const { reset, handleSubmit, watch } = reactHookFormMethods;

  const suggestNewQuestionMutation = useSuggestNewQuestion();

  const selectedQuestionType = watch("questionType");

  return (
    <GenericDialog
      dialogTitle="Suggest a new Question"
      dialogSubtitle={
        "Every question suggested goes through a screening process. We'll let you know as soon as the process is done."
      }
      maxWidth="sm"
      triggerButton={
        <Button
          title={"Suggest a new Question"}
          variant="outlined"
          sx={{
            borderRadius: "8px",
            padding: "10px 16px",
          }}
        >
          <Typography
            sx={{
              textTransform: "none",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations.semiBold,
            }}
          >
            Suggest a new Question
          </Typography>
        </Button>
      }
      triggerButtonDisplay="inline"
      submitButtonType={SUBMIT_BUTTON_TYPES.submit}
      submitButtonText={"Submit"}
      onSubmitCallback={handleSubmit((suggestionQuestionData) => {
        suggestNewQuestionMutation.mutateAsync({
          text: suggestionQuestionData.questionText,
          notes: {
            "Choice type": suggestionQuestionData.questionType,
            "Choice type text choices":
              suggestionQuestionData.possibleTextChoices,
            "Quetsion extra notes": suggestionQuestionData.additionalNotes,
            userEmail: user.email,
            username: user.username,
          },
        });
      })}
      closeButtonText={"Cancel"}
      onCloseCallback={function onCloseDialogCallbackHandler() {
        reset();
        suggestNewQuestionMutation.reset();
      }}
      isSubmitting={suggestNewQuestionMutation.isLoading}
      isDone={suggestNewQuestionMutation.isSuccess}
    >
      <FormProvider {...reactHookFormMethods}>
        <form method="POST">
          <Box display="flex" flexDirection="column" gap={2}>
            <FormTextInput
              label="Question Text"
              name="questionText"
              placeholder="Template Name"
              type="text"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                borderRadius: "8px",
              }}
            />
            <FormDropdown
              label="Question Type"
              name="questionType"
              options={questionTypesOptions}
            />
            {selectedQuestionType === questionTypesKeys["Text choices"] && (
              <FormTextInput
                label="Possible Text Choices"
                name="possibleTextChoices"
                placeholder="Possible Text Choices (Add them comma-separated)"
                type="text"
                sx={{
                  color: primaryColors.gray[500],
                  backgroundColor: primaryColors.base.white,
                  borderRadius: "8px",
                }}
              />
            )}
            <FormTextarea
              label="Additional Notes"
              name="additionalNotes"
              placeholder="Additional Notes"
              type="text"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                borderRadius: "8px",
                width: "100%",
              }}
            />
          </Box>
        </form>
      </FormProvider>
    </GenericDialog>
  );
};

const questionTypesKeys = {
  "Yes - No": "yes_no",
  "Good - Neutral - Bad": "good_neutral_bad",
  "Excellent - Good - Average - Need Improvement - Bad":
    "excellent_good_average_needImprovement_bad",
  "Scale from 1 to 5": "scale_1_5",
  "Scale from 1 to 10": "scale_1_10",
  "Text choices": "customizedText",
};

const questionTypesOptions = [
  { value: questionTypesKeys["Yes - No"], label: "Yes - No" },
  {
    value: questionTypesKeys["Good - Neutral - Bad"],
    label: "Good - Neutral - Bad",
  },
  {
    value:
      questionTypesKeys["Excellent - Good - Average - Need Improvement - Bad"],
    label: "Excellent - Good - Average - Need Improvement - Bad",
  },
  { value: questionTypesKeys["Scale from 1 to 5"], label: "Scale from 1 to 5" },
  {
    value: questionTypesKeys["Scale from 1 to 10"],
    label: "Scale from 1 to 10",
  },
  { value: questionTypesKeys["Text choices"], label: "Text choices" },
];
