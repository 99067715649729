import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const defaultTemplatesListParams = {
  page: 1,
};

const getAllTemplates = ({ page }) => {
  const queryData = {
    params: {
      page,
    },
  };
  const requestURL = `${API_URL}/templates`;

  return melior.get(requestURL, queryData);
};

export const useGetAllTemplates = (
  { params, config } = {
    params: defaultTemplatesListParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["templates", params],
    queryFn: () => getAllTemplates(params),
    keepPreviousData: true,
  });
};
