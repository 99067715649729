import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
    NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const assignTemplateToEntities = ({
  templateID,
  branches,
  divisions,
  channels,
}) => {
  let requestURL = `${API_URL}/templates/${templateID}/apply`;

  return melior.post(requestURL, {
    branches,
    divisions,
    channels,
  });
};

export const useAssignTemplateToEntities = ({ config } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["templates"], { type: "active" });

      fireNotification({
        title: `Assignation has been applied successfully`,
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: (error, variables) => {
      fireNotification({
        title: `Template assignation failed, please try again later`,
        description: error.response.data.detail,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: assignTemplateToEntities,
  });
};
