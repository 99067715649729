import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { primaryColors } from "../../helpers/customColors";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
import { LoadingButton } from "@mui/lab";

export const GenericDialog = ({
  children = false,
  triggerButton,
  triggerButtonDisplay = "block",
  dialogTitle,
  dialogSubtitle,
  dialogContentText,
  dialogActionsContent,
  submitButtonText = undefined,
  closeButtonText = undefined,
  isDone = false,
  disableDialogTriggerAction = false,
  handleDisabledButtonClicked = undefined,
  maxWidth = undefined,
  scrollType = "paper",
  openByDefault = false,
  forceReopen = false,
  preventClosing = false,
  variant = "secondary",
  disableSubmitCondition = undefined,
  submitButtonType = SUBMIT_BUTTON_TYPES.submit,
  onSubmitCallback = undefined,
  onCloseCallback = undefined,
  onOpenCallback = undefined,
  stopPropagation = false,
  isSubmitting = false,
  preventOpening = false,
  isHidden,
}) => {
  const [open, setOpen] = useState(false);

  let getSubmitButtonColor = () => {
    switch (submitButtonType) {
      case SUBMIT_BUTTON_TYPES.submit: {
        if (disableSubmitCondition) return primaryColors.brand[200];
        else return primaryColors.brand[500];
      }

      case SUBMIT_BUTTON_TYPES.delete:
        return primaryColors.error[600];

      default:
        break;
    }
  };

  const handleClickOpen = () => (e) => {
    if (!disableDialogTriggerAction) {
      !preventOpening && setOpen(true);
      onOpenCallback && onOpenCallback(e);
      stopPropagation && e.stopPropagation();
    } else handleDisabledButtonClicked && handleDisabledButtonClicked();
  };
  const handleClose = useCallback(
    (e) => {
      setOpen(false);
      onCloseCallback && onCloseCallback(e);
      e?.stopPropagation();
    },
    [onCloseCallback]
  );

  const handleSubmit = useCallback(
    (e) => {
      onSubmitCallback && onSubmitCallback();
      e.stopPropagation();
    },
    [onSubmitCallback]
  );

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    if (isDone) {
      handleClose();
    }
  }, [handleClose, isDone]);

  useEffect(() => {
    triggerButton === undefined && setOpen(openByDefault);
  }, [openByDefault, triggerButton]);

  useEffect(() => {
    if (forceReopen === true) {
      setOpen(true);
    }
  }, [forceReopen]);

  const triggerDialog =
    triggerButton !== undefined
      ? React.cloneElement(triggerButton, {
          onClick: handleClickOpen(),
          style: {
            opacity: isHidden
              ? 0
              : disableDialogTriggerAction
              ? 0.5
              : undefined,
            cursor: disableDialogTriggerAction ? "not-allowed" : undefined,
            position: isHidden ? "absolute" : "initial",
          },
        })
      : null;

  useEffect(() => {
    if (openByDefault) {
      setOpen(true);
    }
  }, [openByDefault]);

  return (
    <Box
      display={triggerButtonDisplay}
      sx={{ position: isHidden ? "absolute" : "initial" }}
    >
      {triggerButton && triggerDialog}
      <Dialog
        onClick={function disableEventPropagation(e) {
          e.stopPropagation();
        }}
        open={open}
        onClose={(e) => {
          if (!preventClosing) handleClose(e);
        }}
        fullWidth={maxWidth !== false ? true : undefined}
        maxWidth={maxWidth ? maxWidth : "sm"}
        scroll={scrollType}
        bgcolor={primaryColors.base.white}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          color={variant}
          sx={{
            fontWeight: "bold",
            fontSize: "150%",
            textAlign: "start",
            border: "none",
            padding: 0,
            paddingY: 2,
            paddingX: 2,
          }}
          id="scroll-dialog-title"
        >
          <Typography
            sx={{
              color: primaryColors.gray[900],
              fontSize: fontSizeVariations["text-lg"],
              fontWeight: fontWeightVariations["bold"],
              marginBottom: "8px",
            }}
          >
            {dialogTitle}
          </Typography>
          <Box display="flex flex-col">
            {dialogSubtitle && (
              <Typography
                sx={{
                  color: primaryColors.gray[500],
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations["medium"],
                }}
              >
                {dialogSubtitle}
              </Typography>
            )}
          </Box>
          {!preventClosing && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>

        {children && (
          <DialogContent
            sx={{
              padding: `20px !important`,
            }}
          >
            {dialogContentText && (
              <DialogContentText sx={{ textAlign: "center" }}>
                {dialogContentText}
              </DialogContentText>
            )}
            {children}
          </DialogContent>
        )}

        {((!preventClosing && closeButtonText) || dialogActionsContent) && (
          <DialogActions
            sx={{
              padding: 0,
              paddingY: 1,
              paddingX: 2,
            }}
          >
            <Box display="flex" gap={2} marginY="12px" width="100%">
              {closeButtonText && (
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{
                    border: `1px solid ${primaryColors.gray[300]}`,
                    borderRadius: "8px",
                    padding: "10px 18px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      color: primaryColors.gray[700],
                      fontSize: fontSizeVariations["text-md"],
                      fontWeight: fontWeightVariations["semiBold"],
                    }}
                  >
                    {closeButtonText}
                  </Typography>
                </Button>
              )}
              {handleSubmit && submitButtonText && (
                <LoadingButton
                  variant="outlined"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={disableSubmitCondition}
                  sx={{
                    padding: "10px 18px",
                    width: "100%",
                    borderRadius: "8px",
                    border: `1px solid ${getSubmitButtonColor()}`,
                    backgroundColor: getSubmitButtonColor(),
                    "&:hover": {
                      backgroundColor: getSubmitButtonColor(),
                    },
                    textTransform: "capitalize",
                    color: primaryColors.base.white,
                    fontSize: fontSizeVariations["text-md"],
                    fontWeight: fontWeightVariations["semiBold"],
                  }}
                  loading={isSubmitting}
                >
                  <span>{submitButtonText}</span>
                </LoadingButton>
              )}
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};

export const SUBMIT_BUTTON_TYPES = {
  submit: "SUBMIT",
  delete: "DELETE",
};
